<template>
  <div>
    <template v-if="currentNewPricingPlan.includesLearningSystem">
      <h3 v-if="title">{{ title }}</h3>
      <h4 class="text-muted" v-if="!noAddOrRemove && subTitle">{{ subTitle }}</h4>
    </template>
    <child v-for="(child, idx) in children" :no-add-or-remove="noAddOrRemove" :only-course-change="onlyCourseChange" :index="idx" :key="idx" :$v="$v.$each[idx]" />
    <button v-if="!(onlyCourseChange || noAddOrRemove) && children.length < 5 && currentNewPricingPlan.includesLearningSystem" class="btn btn-primary" @click="addChild">Add Student</button>
  </div>
</template>

<script>

import { mapMultiRowFields } from 'vuex-map-fields'
import { mapMutations, mapGetters } from 'vuex'

import child from './Child'

export default {
  components: { child },
  props: {
    $v: {
      type: Object,
      required: true
    },
    onlyCourseChange: {
      type: Boolean,
      default: false
    },
    noAddOrRemove: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Student information'
    },
    subTitle: {
      type: String,
      default: 'You can add up to 5 students'
    }
  },
  computed: {
    ...mapMultiRowFields(['children']),
    ...mapGetters(['currentNewPricingPlan'])
  },
  created () {
    this.$watch('$v.$invalid', (invalid) => {
      this.$emit('invalidity', invalid)
    }, { immediate: true })
  },
  methods: {
    ...mapMutations(['addChild'])
  }
}
</script>
