<template>
  <div class="ee-iris-lite-ui">
    <div v-if="development">
      <b-button
        size="sm"
        variant="info"
        @click="
          $store.commit('updateField', { path: 'step', value: STEP_FIND_PRESENTATION });
          $store.commit('updateField', { path: 'submitting', value: false });
        "
      >
        Jump to first step
      </b-button>
      <b-button
        size="sm"
        variant="info"
        @click="
          $store.commit('updateField', {
            path: 'step',
            value: $store.state.step - 1
          });
          $store.commit('updateField', { path: 'submitting', value: false });
        "
      >
        Previous step only
      </b-button>
      <b-button size="sm" variant="info" @click="forceLoadStateFromUrl">Load State from URL</b-button>
    </div>
    <b-alert :show="!!flashMessage" variant="primary">
      {{ flashMessage }}
    </b-alert>
    <template v-if="submitting">
      <spinner style="margin: 3rem auto" color="rgb(24, 108, 149)" />
    </template>
    <template v-else>
      <b-alert v-if="submitMessage" variant="warning" show dismissible>
        {{ submitMessage }}
      </b-alert>
      <template v-if="step === STEP_FIND_PRESENTATION">
        <select-presentation />
      </template>
      <template v-else-if="step === STEP_GET_INITIAL_LOCATION">
        <get-location
          title-fetching-message="Preparing IRIS"
          no-status-while-waiting
          lead-message="When prompted, click Allow Location Services"
          @position="$store.commit('setPosition', $event)"
        />
      </template>
      <template v-else-if="step === STEP_PRIVACY_PROMPT">
        <b-modal
          size="lg"
          title=""
          centered
          visible
          ok-only
          hide-header-close
          no-close-on-backdrop
          no-close-on-esc
          @hidden="$store.commit('setPrivacyPolicyAgreed')"
          :ok-disabled="!privacyPolicyAccept"
        >
          <template slot="modal-header">
            <h5 class="modal-title">
              <img
                crossorigin="anonymous"
                class="d-block float-lg-right"
                src="@/assets/logo_estia.png"
              />
              We're committed to protecting and respecting your privacy....
            </h5>
          </template>
          <p class="my-4">
            We need to collect certain information from you to enable us to
            supply the learning programme to your family, plus other information
            that may be required for the provision of how you have chosen to pay
            for the programme.
          </p>
          <p>
            You can find out more in our Privacy Notice at
            <a
              target="_blank"
              href="/privacy-policy"
              >https://www.estialabs.co.uk/privacy-policy</a
            >.
          </p>
          <b-form-checkbox v-model="privacyPolicyAccept"
            >I have read and accept the above.</b-form-checkbox
          >
        </b-modal>
      </template>
      <template v-else-if="step === STEP_FAMILY_INFORMATION">
        <family-details-form
          :$v="$v.step0data.familyDetails"
          :loqateMessages="loqateMessages"
        />
        <finance-form :$v="$v.step0data" :loqateMessages="loqateMessages" />
        <template v-if="!$store.state.fixedSubscriptionModelEnabled || $store.getters.isSubscriptionPlanDec2021">
          <course-selection-form :$v="$v.step0data.courseSelections" />
          <items-for-sale :$v="$v.step0data.courseSelections" />
          <children-form
            style="margin-bottom: 1rem;"
            :$v="$v.step0data.children"
            :no-add-or-remove="$store.state.createAccountResult !== null"
          />
          <branch-office-form v-if="!$store.state.fixedSubscriptionModelEnabled"  :$v="$v.step0data.branchOffice" />
          <div @mouseover="$v.step0data.$touch">
            <b-button
              variant="primary"
              :disabled="$v.step0data.$invalid"
              @click="createOrUpdateFamilyAccount"
              >
              {{$store.state.createAccountResult ? 'Update' : 'Create'}} Account
              </b-button
            >
            <b-button class="ml-3" v-if="$store.state.createAccountResult !== null && $store.state.savedFamilyInfo !== null" @click="cancelUpdatingFamilyInformation">Cancel</b-button>
          </div>
        </template>
      </template>
      <template v-else-if="step === STEP_UNLOCK">
        <!-- Unlock via a code given via sms/email whatever -->
        <unlock-code
          :$v="$v.step1data"
          @enter="!$v.step1data.$invalid && unlockAccount()"
        >
          <div @mouseover="$v.step1data.$touch">
            <button
              class="btn btn-primary"
              :disabled="$v.step1data.$invalid"
              @click="unlockAccount"
            >
              Unlock Account
            </button>
          </div>
        </unlock-code>
      </template>
      <template v-else-if="step === STEP_CONFIRM_EMAILS_RECEIVED">
        <confirm-email @ok="confirmEmailsReceived" />
      </template>
      <template v-else-if="step === STEP_FINANCE">
        <b-button variant="primary" @click="returnToFamilyInformation" title="Return to edit family information">Return to family information</b-button>
        <finance-form-2 :$v="$v.step2data" :loqate-messages="loqateMessages" />
        <other-paperwork-info :$loqate="$loqate" :$v="$v.step2data" :loqate-messages="loqateMessages" />
        <b-alert show variant="warning" v-if="$store.state.recurlyApiLastError" ref="recurlyWarning">
          <p>Failed to setup automatic Direct Debit: Check Sort Code and Account Number</p>
          <p class="small">{{ $store.state.recurlyApiLastError }}</p>
          <b-button variant="primary"
            :disabled="$v.step2data.$invalid"
            @click="bypassRecurlyConfirmation"
          >Bypass automatic Direct Debit setup and Create Documentation</b-button>
        </b-alert>
        <div @mouseover="$v.step2data.$touch">
          <button
            class="btn btn-primary"
            :disabled="$v.step2data.$invalid"
            @click="createDocuments()"
            v-text="$store.state.bypassPayment ? `Continue` : 'Submit Direct Debit Details'"
          />
        </div>
      </template>
      <template v-else-if="step === STEP_CHOOSE_SESSIONS">
        <lazy-iris-schedule-sessions @ok="sessionsChosen"/>
      </template>
      <template v-else-if="step === STEP_SIGNING">
        <customer-confirmation @ok="signDocumentsCompleted" />
      </template>
      <template v-else-if="step === STEP_LTL_VIDEO_CONFIRMATION">
        <video-confirmation :$v="$v.videoConfirmation" />
        <div @mouseover="$v.videoConfirmation.$touch">
          <button
            class="btn btn-primary"
            :disabled="$v.videoConfirmation.$invalid"
            @click="videoConfirmationFinish"
          >
            {{ step2data.decision !== 'FR' ? 'Submit' : 'Finish' }}
          </button>
        </div>
      </template>
      <template v-else-if="step === STEP_UPLOADING_DOCUMENTS">
        <!-- The driving to the next step is done inside store this just provides a UI to retry etc.. -->
        <document-upload-progress
          :$v="$v.step4data"
          :pdf-blob-promises="pdfs"
        />
      </template>
      <template v-else>
        <complete />
      </template>
    </template>
  </div>
</template>

<script>
// step 0 steps
import FamilyDetailsForm from './components/FamilyDetailsForm'

import CourseSelectionForm from './components/CourseSelectionForm'
import ChildrenForm from './components/ChildrenForm'
import financeForm from './components/FinanceForm'
import branchOfficeForm from './components/BranchOfficeForm'
import documentUploadProgress from './components/DocumentUploadProgress'
import complete from './components/Complete'
import getLocation from './components/GetLocation'
import confirmEmail from './components/ConfirmEmail'
import VideoConfirmation from './components/VideoConfirmation'
import ItemsForSale from './components/ItemsForSale'

// step 1 step
import unlockCode from './components/UnlockCode'

// step 2 steps
import financeForm2 from './components/FinanceForm2'
import otherPaperworkInfo from './components/OtherPaperworkInfo'

// step 3 steps (documents)
import CustomerConfirmation from './components/CustomerConfirmation.vue'

// spinner
import spinner from 'vue-spinner/src/RiseLoader.vue'

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import {
  required,
  email,
  sameAs,
  not,
  numeric,
  requiredIf,
  helpers,
  minLength,
  maxLength,
  minValue
} from 'vuelidate/lib/validators'

import {
  courseSelections,
  children,
  deposit as depositValidations,
  payments as paymentValidations,
  term as termValidations,
  ukPostcodeValid,
  altPayerValidations,
  altPayerValidationsFn,
  loqateValidator,
  hasSomeLowerCase,
  hasSomeUpperCase
} from './validations'

import courseSelectionsMixin from './mixins/courseSelectionsMixin'

import moment from 'moment'
import {
  STEP_FIND_PRESENTATION,
  DEPOSIT_ALT_PAYER,
  DIRECT_DEBIT_ALT_PAYER,
  STEP_GET_INITIAL_LOCATION,
  STEP_PRIVACY_PROMPT,
  STEP_FAMILY_INFORMATION,
  STEP_UNLOCK,
  STEP_CONFIRM_EMAILS_RECEIVED,
  STEP_FINANCE,
  STEP_SIGNING,
  STEP_UPLOADING_DOCUMENTS,
  STEP_LTL_VIDEO_CONFIRMATION,
  STEP_CHOOSE_SESSIONS
} from './constants'
import SelectPresentation from './SelectPresentation.vue'

const mobilePhoneValidations = {
  required,
  numeric,
  maxLength: maxLength(64)
}

const duplicateEmailCheck = async function (value) {
  // if value is blank just return right away
  if (!helpers.req(value) || !email(value)) return true
  try {
    let response = await this.irisApi.validateEmail(value, {
      instituteCode: this.$store.state.regModelMap.INSTITUTECODE
    })
    return response.isValidate
  } catch (e) {
    this.$store.commit('apiError', e)
    return false
  }
}

export default {
  inject: ['irisApi', 'param', '$loqate'],
  data () {
    return {
      loqateMessages: {
        email: '',
        alternativeEmail: '',
        altPayerEmail: '',
        depositPayerEmail: '',
        ddPayerEmail: ''
      },
      pdfs: {},
      STEP_GET_INITIAL_LOCATION,
      STEP_PRIVACY_PROMPT,
      STEP_FAMILY_INFORMATION,
      STEP_UNLOCK,
      STEP_CONFIRM_EMAILS_RECEIVED,
      STEP_FINANCE,
      STEP_SIGNING,
      STEP_UPLOADING_DOCUMENTS,
      STEP_LTL_VIDEO_CONFIRMATION,
      STEP_FIND_PRESENTATION,
      STEP_CHOOSE_SESSIONS
    }
  },
  components: {
    getLocation,
    FamilyDetailsForm,
    CourseSelectionForm,
    ChildrenForm,
    financeForm,
    unlockCode,
    financeForm2,
    otherPaperworkInfo,
    branchOfficeForm,
    spinner,
    documentUploadProgress,
    CustomerConfirmation,
    complete,
    confirmEmail,
    VideoConfirmation,
    ItemsForSale,
    SelectPresentation
  },
  mixins: [courseSelectionsMixin],
  watch: {
    'finance.paymentMethod': function () {
      this.$v.step0data.courseSelections.$touch()
    },
    submitting: {
      immediate: true,
      handler (submitting) {
        if (!submitting && this.step === this.STEP_FINANCE && this.$store.state.recurlyApiLastError) {
          return this.$nextTick().then(() => {
            if (this.$refs.recurlyWarning) {
              return this.$refs.recurlyWarning.$el.scrollIntoView()
            }
          })
        }
      }
    }
  },
  computed: {
    development: () => process.env.NODE_ENV === 'development',
    ...mapFields(['privacyPolicyAccept']),
    ...mapState([
      'flashMessage',
      'step',
      'submitMessage',
      'submitting',
      'familyInformation',
      'videoConfirmation'
    ]),
    step0data () {
      return {
        familyDetails: {
          ...this.$store.state.familyInformation,
          ...this.$store.state.address
        },
        altPayerInformation: this.$store.state.altPayerInformation,
        altPayer: this.$store.getters.altPayer,
        finance: this.$store.state.finance,
        children: this.$store.state.children,
        branchOffice: this.$store.state.branchOffice,
        courseSelections: {
          ...this.courseSelections,
          monthsTerm: this.$store.state.finance.monthsTerm
        }
      }
    },
    finance () {
      return this.$store.state.finance
    },
    step3data: function () {
      return {
        documents: this.$store.getters.documents,
        documentsBySigner: this.$store.getters.documentsBySigner,
        allDocumentsSigned: this.$store.getters.allDocumentsSigned
      }
    },
    step4data () {
      return {
        documents: this.$store.getters.documents
      }
    },
    ...mapState({
      step1data: state => {
        return {
          unlockCode: state.unlockCode,
          mobilePhone: state.familyInformation.mobilePhone,
          email: state.familyInformation.email
        }
      }
    }),
    step2data () {
      return {
        ...this.$store.state.finance,
        altPayerInformation: this.$store.state.altPayerInformation,
        altPayer: this.$store.getters.altPayer,
        payments: this.$store.getters['payments/paymentsWithDollars'],
        instalments: {
          selectedPaymentMethod: this.$store.state.instalments.selectedPaymentMethod,
          firstPaymentDue: this.$store.getters['instalments/firstPaymentDue']
        }
      }
    }
  },
  validations () {
    let validations = {
      videoConfirmation: {}
    }
    validations.step0data = {
      branchOffice: {
        required: requiredIf(function () {
          return !this.$store.state.fixedSubscriptionModelEnabled
        })
      },
      familyDetails: {
        title: {
          required,
          maxLength: maxLength(20)
        },
        firstName: {
          required,
          maxLength: maxLength(148),
          hasSomeLowerCase,
          hasSomeUpperCase
        },
        dob: {
          required,
          maxValue: function (dateStr) {
            return moment(dateStr, 'YYYY-MM-DD').isBefore(
              this.$store.getters.moment().subtract(18, 'years')
            )
          },
          minValue: function (dateStr) {
            return moment(dateStr, 'YYYY-MM-DD').isAfter(
              this.$store.getters.moment().subtract(120, 'years')
            )
          }
        },
        authorisedPersons: {
          maxLength: maxLength(255),
          hasSomeLowerCase,
          hasSomeUpperCase
        },
        lastName: {
          required,
          maxLength: maxLength(48),
          hasSomeLowerCase,
          hasSomeUpperCase
        },
        address1: {
          required,
          maxLength: maxLength(148)
        },
        address2: {
          maxLength: maxLength(148)
        },
        state: {
          required,
          maxLength: maxLength(32)
        },
        city: {
          required,
          maxLength: maxLength(32)
        },
        postcode: {
          required,
          ukPostcodeValid,
          maxLength: maxLength(16)
        },
        email: {
          required,
          email,
          loqate: loqateValidator(function (response) {
            this.loqateMessages.email = response.ResponseMessage
          }),
          maxLength: maxLength(128)
        },
        alternativeEmail: {
          email,
          notEmail1: not(sameAs('email')),
          loqate: loqateValidator(function (response) {
            this.loqateMessages.alternativeEmail = response.ResponseMessage
          }),
          maxLength: maxLength(128)
        },
        emailConfirm: {
          required,
          sameAsEmail: sameAs('email')
        },
        alternativeEmailConfirm: {
          sameAsAlternativeEmail: sameAs('alternativeEmail')
        },
        mobilePhone: mobilePhoneValidations,
        homePhone: {
          numeric,
          maxLength: maxLength(64)
        },
        workPhone: {
          numeric,
          maxLength: maxLength(64)
        }
      },
      altPayerInformation: {},
      courseSelections: courseSelections.call(this),
      children,
      finance: {
        paymentMethod: {
          required
        }
      }
    }

    if (this.$store.getters.finalSaleType === 'LTL') {
      validations.videoConfirmation = {
        accept: {
          required
        },
        endTime: {
          required
        },
        startTime: {
          required
        },
        progress: {
          required,
          minValue: minValue(1)
        }
      }
    }

    // Add in duplicate email checking if account not already created or upgrading a MFF account
    if (this.$store.state.createAccountResult === null && !this.$store.state.upgradingAccount && !this.$store.state.documentationOnlyMode) {
      validations.step0data.familyDetails.email.duplicateEmailCheck = duplicateEmailCheck
      validations.step0data.familyDetails.alternativeEmail.duplicateEmailCheck = duplicateEmailCheck
    }

    validations.step1data = {
      unlockCode: {
        required,
        numeric
      },
      email: {
        required,
        email,
        maxLength: maxLength(128)
      },
      mobilePhone: mobilePhoneValidations
    }

    validations.step2data = {
      ...depositValidations.call(this),
      payments: paymentValidations.call(this),
      ...termValidations.call(this),
      selfDeclaration: {
        required: requiredIf(function () {
          return this.$store.getters.finalSaleType === 'LTL'
        })
      },
      complianceComments: {
        required: requiredIf(function () {
          return this.$store.getters.finalSaleType === 'LTL'
        })
      },
      proofID: {
        required: function (value) {
          if (
            ['LTL', 'APS'].indexOf(this.step0data.finance.paymentMethod) > -1
          ) {
            return !!value
          } else {
            return true
          }
        }
      },
      proofIncome: {
        required: function (value) {
          if (
            this.step0data.finance.paymentMethod === 'LTL' &&
            this.step0data.finance.decision === 'AWC'
          ) {
            return !!value
          } else {
            return true
          }
        }
      },
      instalments: {
        selectedPaymentMethod: {
          required: requiredIf(function () {
            return this.$store.getters.isStripeDirectDebit
          })
        },
        firstPaymentDue: {
          required: requiredIf(function () {
            return this.$store.getters.requiresDirectDebitFirstDate
          }),
          minValue: function (dateMoment) {
            if (this.$store.getters['instalments/firstPossibleDirectDebitDate']) {
              return !dateMoment || dateMoment.isSameOrAfter(this.$store.getters['instalments/firstPossibleDirectDebitDate'], 'day')
            }
            return true
          },
          maxValue: function (dateMoment) {
            if (this.$store.getters['instalments/lastPossibleDirectDebitDate']) {
              return !dateMoment || dateMoment.isSameOrBefore(this.$store.getters['instalments/lastPossibleDirectDebitDate'], 'day')
            }
            return true
          }
        }
      },
      dd: {
        confirmation: {
          required (value) {
            if (
              this.$store.getters.hasDirectDebit && this.$store.state.recurlyDirectDebit && !this.$store.state.finance.recurlyDDPaymentMethodId
            ) {
              return !!value
            } else {
              return true
            }
          }
        },
        ddAltPayerInfo: {},
        accountHolder: {
          required: requiredIf(function () {
            return this.$store.getters.hasDirectDebit && !this.$store.state.finance.recurlyDDPaymentMethodId
          })
        },
        sortCode: {
          required: requiredIf(function () {
            return this.$store.getters.hasDirectDebit && !this.$store.state.finance.recurlyDDPaymentMethodId
          }),
          sortCode: async function (code) {
            if (!helpers.req(code)) return true // not required
            if (!code.match(/[_\d]{2}-[_\d]{2}-[_\d]{2}/)) return false // not formatted right
            // only call api it fully formatted
            if (code.match(/\d{2}-\d{2}-\d{2}/)) {
              try {
                let response = await this.$loqate.bankBySortCode(code)
                return !!response
              } catch (exception) {
                // eslint-disable-next-line no-console
                console.log(exception)
                return true
              }
            }
          }
        },
        bankName: {
          required: requiredIf(function () {
            return this.$store.getters.hasDirectDebit && !this.$store.state.finance.recurlyDDPaymentMethodId
          })
        },
        bankAddress: {
          required: requiredIf(function () {
            return this.$store.getters.hasDirectDebit && !this.$store.state.finance.recurlyDDPaymentMethodId
          })
        },
        accountNumber: {
          required: requiredIf(function () {
            return this.$store.getters.hasDirectDebit && !this.$store.state.finance.recurlyDDPaymentMethodId
          }),
          numeric,
          minLength: minLength(8),
          maxLength: maxLength(8)
        }
      },
      depositAltPayerInfo: {},
      altPayerInformation: {}
    }

    // same data is in both step 0 and 2 (family details and finance page)
    if (this.step0data.altPayer) {
      validations.step2data.altPayerInformation = validations.step0data.altPayerInformation = { ...altPayerValidations }
      if (this.$store.getters.ltlExtraConfirmations) {
        validations.step0data.altPayerInformation.mobilePhone = { required, numeric }
      }
    }
    if (this.$store.getters['payments/firstPaymentWithDepositAltPayer']) {
      validations.step2data.depositAltPayerInfo = altPayerValidationsFn({ loqateKey: 'depositPayerEmail' })
    }
    if (this.step2data.dd.ddAltPayer === DIRECT_DEBIT_ALT_PAYER) {
      validations.step2data.dd.ddAltPayerInfo = altPayerValidationsFn({ loqateKey: 'ddPayerEmail' })
    }

    validations.step3data = {
      // signing validations here TODO REMOVE OLD VALIDATION SAME AS NEW!
      documents: {
        minLength: minLength(1), // need at least 1 document to sign
        $each: {
          displayedAt: {
            required: requiredIf(document => !document.hidden) // need updatedAt value to be set by viewing the document
          },
          signingPoints: { // TODO THIS MAY NOT BE CORRECT
            $each: {
              value: {
                mustBeSigned: value => value === true // value must be true and nothing else
              },
              updatedAt: {
                required // needs a timestamp
              }
            }
          }
        }
      },
      documentsBySigner: {
        $each: {
          $each: {
            displayedAt: {
              required
            },
            signingPoints: {
              $each: {
                value: {
                  mustBeSigned: value => value === true // value must be true and nothing else
                },
                updatedAt: {
                  required // needs a timestamp
                }
              }
            }
          }
        }
      }
    }

    validations.step4data = {
      documents: {
        minLength: minLength(1),
        $each: {
          uploadedAt: {
            required
          }
        }
      }
    }

    return validations
  },
  mounted () {
    if (this.step === this.STEP_UPLOADING_DOCUMENTS) {
      return this.$store.dispatch('signDocumentsCompleted')
    }
  },
  methods: {
    ...mapMutations(['returnToFamilyInformation']),
    ...mapActions(['videoConfirmationFinish', 'cancelUpdatingFamilyInformation', 'createOrUpdateFamilyAccount', 'unlockAccount', 'createDocuments', 'confirmEmailsReceived', 'sessionsChosen']),
    signDocumentsCompleted () {
      this.$store.dispatch('signDocumentsCompleted', {
        pdfBlobPromises: this.pdfs
      })
    },
    bypassRecurlyConfirmation () {
      return this.$bvModal.msgBoxConfirm('Please only do this if you are unable to get around this error without updating account details').then((response) => {
        if (response) {
          return this.createDocuments(true)
        }
      })
    },
    // dealing with promise here otherwise the upload screen MAY NOT HAVE THE PDF yet. So just assume as promise for dealing to get blob
    savePdfForUpload ({ id, blobPromise }) {
      blobPromise.catch(_e => {
        this.$delete(this.pdfs, id)
      })
      this.$set(this.pdfs, id, blobPromise)
    },
    async forceLoadStateFromUrl () {
      var url = prompt('Enter the url to load the message from?')
      if (url) {
        let data = await fetch(url)
        let json = await data.json()
        this.$store.replaceState(json.state)
      }
    }
  }
}
</script>

<style>
.ee-fullpage .ee-iris-lite-ui {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ee-fullpage .ee-documents {
  flex: 2;
}
</style>
