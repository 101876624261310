<template>
  <div>
    <b-jumbotron>
      <template v-if="step === STEP_COMPLETED_SAVED">
        <h1>Family Enrolment Saved</h1>
        <p>Email has been sent to the office. Office has to check &amp; update the finance to continue on</p>
      </template>
      <template v-else-if="step === STEP_COMPLETED_SENT_TO_CUSTOMER">
        <h1>Documents sent to customer</h1>
        <p>All of the finance documents that needed to complete this sale have been sent to customer email : {{ $store.state.familyInformation.email }}</p>
      </template>
      <template v-else-if="step === STEP_CANCELLED_ACCOUNT">
        <h1>Customer account cancelled</h1>
        <p>Customers account has been cancelled</p>
      </template>
      <template v-else>
        <h1 class="text-center">Family Enrolment Complete!</h1>
      </template>
      <p class="text-center" v-if="backLink">
        <b-button :to="{ name: 'index' }" variant="primary">Back to home</b-button>
      </p>
      <p class="text-center" v-else>This window can now be closed.</p>
    </b-jumbotron>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { STEP_COMPLETED_SAVED, STEP_COMPLETED_SENT_TO_CUSTOMER, STEP_CANCELLED_ACCOUNT } from '@iris/constants'

export default {
  inject: ['backLink'],
  data: () => ({
    STEP_COMPLETED_SAVED,
    STEP_COMPLETED_SENT_TO_CUSTOMER,
    STEP_CANCELLED_ACCOUNT
  }),
  computed: {
    ...mapState(['step'])
  }
}
</script>
