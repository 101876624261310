<template>
  <payment-common>
    <template #header>
      <b-alert variant="primary" v-if="cardAuthActive" show>Please complete card authentication</b-alert>
      <b-alert variant="primary" v-else-if="processing" show>Submitting card payment</b-alert>
      <b-alert variant="warning" v-if="error" show dismissible>{{ error.message }}</b-alert>
      <b-alert variant="success" v-if="internalValue.finalised" show dismissible>Payment successfully processed</b-alert>
    </template>
    <recurly-elements />
    <template #footer v-if="!internalValue.finalised">
      <spinner class="mt-3" v-if="processing" color="rgb(24, 108, 149)" />
      <b-button class="float-right ml-3" variant="primary" @mouseover="$v.paidBy.$touch" @click="processPayment" :disabled="!canEdit || !cardDetailsOk">Process payment for {{ $n(amount, 'currency') }}</b-button>
    </template>
  </payment-common>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, computed } from 'vue'
import { RecurlyCreditCardPayment } from '@iris/store/payments/types'
import paymentMixins from './mixin'
import { useRecurly } from './recurly'
import { PaymentType } from '@iris/nestjs-interfaces-recurly'
import spinner from 'vue-spinner/src/RiseLoader.vue'
import RecurlyElements from './RecurlyElements.vue'
import { IrisGetters } from '../../store/types'

const mixin = paymentMixins<RecurlyCreditCardPayment>({ includeCanEdit: false })
export default defineComponent({
  // extends: mixin,
  mixins: [mixin],
  components: {
    // this is just html structure not specific to
    spinner,
    RecurlyElements
  },
  setup () {
    const { public: { recurlyPublicKey } } = useRuntimeConfig()
    const currentInstance = getCurrentInstance()!
    const instance = currentInstance.proxy as unknown as InstanceType<typeof mixin>
    return useRecurly({
      paymentTypeProps: computed(() => {
        return {
          type: PaymentType.SINGLE,
          monthsInitialPayment: (instance.$store.getters as IrisGetters).currentNewPricingPlan.upfrontPayment?.period.asMonths()
        }
      }),
      publicKey: recurlyPublicKey,
      value: computed<RecurlyCreditCardPayment>({
        get () {
          return instance.internalValue
        },
        set (v) {
          instance.internalValue = v
        }
      })
    })
  }
})
</script>

<style>

  @import url('https://js.recurly.com/v4/recurly.css');
  /* .form-control.custom-focus {
    color: #495057;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .form-control.is-valid.custom-focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  .form-control.is-invalid.custom-focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .stripe-disabled .form-control {
    background-color: #e9ecef;
  } */

</style>
