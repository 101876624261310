<template>
  <div>
    <h3 v-if="title">{{ title }}</h3>
    <h4 class="text-muted" v-if="subTitle">{{ subTitle }}</h4>
    <div v-if="currentNewPricingPlan" class="mt-2">
      <p v-if="currentNewPricingPlan.termLength">
        <span class="text-success">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 8 8"><path fill="#28a745" d="M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z"/></svg>
        </span>
        Full membership for {{ currentNewPricingPlan.termLength.asMonths() }} month{{ currentNewPricingPlan.termLength.asMonths() > 1 ? 's' : '' }}
      </p>
      <p v-if="currentNewPricingPlan.includedSessions > 0">
        <span class="text-success">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 8 8"><path fill="#28a745" d="M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z"/></svg>
        </span>
        {{ Math.floor(currentNewPricingPlan.includedSessions * currentNewPricingPlan.termLength.asMonths()) }} individual live teacher session{{ Math.floor(currentNewPricingPlan.includedSessions * currentNewPricingPlan.termLength.asMonths()) > 1 ? 's' : '' }}
      </p>
      <p v-if="currentNewPricingPlan.includesLearningSystem">
        <span class="text-success">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 8 8"><path fill="#28a745" d="M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z"/></svg>
        </span>
        Full access to the EstiaLabs learning platform
      </p>
      <p>
        <template v-if="currentNewPricingPlan.teacherHelpline">
          <span class="text-success">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 8 8"><path fill="#28a745" d="M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z"/></svg>
          </span> Unlimited
        </template>
        <template v-else>
          <span class="text-danger">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#dc3545" width="20" height="20" viewBox="-2 -2 7 7"><path stroke="#dc3545" d="M0 0l3 3m0-3L0 3"/><circle r=".5"/><circle cx="3" r=".5"/><circle cy="3" r=".5"/><circle cx="3" cy="3" r=".5"/></svg>
          </span> No
        </template>
        access to the Teacher Helpline
      </p>
      <p v-if="monthlyInstallment > 0">
        <template v-if="currentNewPricingPlan.upfrontPayment">
          <template v-if="currentNewPricingPlan.upfrontPayment.amount > 0">
            Payment of {{ $n(currentNewPricingPlan.upfrontPayment.amount, 'currency', {maximumFractionDigits: 0}) }}
          </template>
          <template v-else>
            No payment
          </template>
          for the first 
          <template v-if="currentNewPricingPlan.upfrontPayment.period.asMonths() === 1">month</template>
          <template v-else>{{ currentNewPricingPlan.upfrontPayment.period.asMonths() }} months</template>
          then
        </template>
        <template v-else>
          Your Subscription: 
        </template>
        {{$n(monthlyInstallment, 'currency', {maximumFractionDigits: 0})}} every month
      </p>
    </div>
  </div>
</template>

<script>

import courseSelectionCheckBoxes from './CourseSelectionCheckBoxes'
import { mapFields } from 'vuex-map-fields'
import { mapGetters, mapState } from 'vuex'
import PaygRevisionTerm from './PaygRevisionTerm'
import { roundNumber, roundNumberUp } from '@iris/util'

export default {
  components: { courseSelectionCheckBoxes, PaygRevisionTerm },
  props: {
    $v: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return `${this.isSubscriptionPlanDec2021 ? 'Subscription' : 'Programme'} Information`
    },
    subTitle () {
      return this.isSubscriptionPlanDec2021 ? null : 'Click the courses required'
    },
    monthlyBaseInstallment () {
      return roundNumberUp(this.$store.getters.moduleCount.totalForFinanceCalculations * this.$store.getters.subscriptionUnitPriceCents / 100)
    },
    ...mapGetters([
      'coursesList',
      'saleValueWithoutExtras',
      'moduleCount',
      'monthlyInstallment',
      'minimumModules',
      'isSubscriptionPlanDec2021',
      'hasTeacherHelpline',
      'minimumTerm',
      'currentNewPricingPlan'
    ]),
    ...mapState({
      paymentMethod: ({ finance }) => finance.paymentMethod
    }),
    ...mapFields([
      'courseSelections.maths',
      'courseSelections.mathsGCSE',
      'courseSelections.mathsN5',
      'courseSelections.literacy',
      'courseSelections.reading',
      'address.state'
    ]),
    totalModuleCount () {
      return this.$store.getters.moduleCount.total
    }
  },
  methods: {
    monthlyApsNewInstalment (pct = 0) {
      return this.monthlyBaseInstallment - roundNumber(this.monthlyBaseInstallment * pct / 100)
    }
  },
  watch: {
    totalModuleCount () {
      this.$v.totalModuleCount.$touch()
    }
  }
}
</script>

<style lang="scss" scoped>
  .error.invalid-feedback {
    display: block;
  }

  .course-box {
    border: solid 2px;
    position: relative;
    padding: 0px 5px 30px;
    height: 100%;
  }
  .module-count {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    color: white;
    text-align: center;
  }
  $mathsBgColor: rgb(16,114,189);
  $literacyBgColor: rgb(107,85,158);
  $readingBgColor: rgb(56,170,83);

  .total-modules {
    color: rgb(56,120,196);
  }
  .mathematics {
    border-color: $mathsBgColor;
    .module-count {
      background-color: $mathsBgColor;
    }
  }
  .literacy {
    border-color: $literacyBgColor;
    .module-count {
      background-color: $literacyBgColor;
    }
  }
  .reading {
    border-color: $readingBgColor;
    .module-count {
      background-color: $readingBgColor;
    }
  }
  .course-selection-form {
    border-bottom: 1px dashed;
    margin-bottom: 30px;
  }
</style>
